import axios, { type AxiosResponse } from 'axios'

import { type Locale } from './language'

type Service = 'clickup' | 'hubSpot' | 'klaviyo'

export interface ClickUpContactPayload {
  listId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  company: string
  message?: string
  emailCustomFieldName?: string
  phoneCustomFieldName?: string
  companyCustomFieldName?: string
}

export interface ClickUpContactResponse {
  error?: string
}

export interface HubSpotBookADemoPayload {
  formId: string
  consent: boolean
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
  company?: string
}

export interface HubSpotBookADemoResponse {
  error?: string
}

export interface HubSpotNewsletterJoinPayload {
  formId: string
  consent: boolean
  email: string
  name?: string
}

export interface HubSpotNewsletterJoinResponse {
  error?: string
}

export interface HubSpotWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface HubSpotWaitlistJoinResponse {
  error?: string
}

export interface KlaviyoBookADemoPayload {
  listId: string
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
  company?: string
}

export interface KlaviyoBookADemoResponse {
  error?: string
}

export interface KlaviyoNewsletterJoinPayload {
  listId: string
  name?: string
  email: string
}

export interface KlaviyoNewsletterJoinResponse {
  error?: string
}

export interface KlaviyoWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface KlaviyoWaitlistJoinResponse {
  error?: string
}

type BookADemoResponse = KlaviyoBookADemoResponse | HubSpotBookADemoResponse

type ContactResponse = ClickUpContactResponse

type NewsletterJoinResponse =
  | KlaviyoNewsletterJoinResponse
  | HubSpotNewsletterJoinResponse

type WaitlistJoinResponse =
  | KlaviyoWaitlistJoinResponse
  | HubSpotWaitlistJoinResponse

/**
 * Gets URL for an API call.
 */
const getApiUrl = (service: string, action: string) =>
  `/api/${service.toLowerCase()}/${action}`

/**
 * Gets headers for an API call.
 */
const getApiHeaders = (locale: Locale): Record<string, any> => ({
  'Content-Type': 'application/json',
  'X-Locale': locale,
})

/**
 * Makes a request to API endpoint.
 */
const callApi = async <T>(
  url: string,
  payload: any,
  headers: Record<string, any>
) =>
  await axios.post<T, AxiosResponse<T>, string>(url, JSON.stringify(payload), {
    headers,
  })

/**
 * Calls newsletter signup API page.
 */
export const addEmailToNewsletterList = async (
  locale: Locale,
  service: Service,
  newsletterTargetId: string,
  hasAcceptedTerms: boolean,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'newsletter-join')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoNewsletterJoinPayload: KlaviyoNewsletterJoinPayload = {
      listId: newsletterTargetId,
      email,
      name,
    }
    await callApi<NewsletterJoinResponse>(
      url,
      klaviyoNewsletterJoinPayload,
      headers
    )
  }

  if (service === 'hubSpot') {
    const hubSpotNewsletterJoinPayload: HubSpotNewsletterJoinPayload = {
      formId: newsletterTargetId,
      consent: !!hasAcceptedTerms,
      email,
      name,
    }
    await callApi<NewsletterJoinResponse>(
      url,
      hubSpotNewsletterJoinPayload,
      headers
    )
  }
}

/**
 * Calls waitlist signup API page.
 */
export const addEmailToWaitlist = async (
  locale: Locale,
  service: Service,
  variantId: number,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'waitlist-join')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoWaitlistJoinPayload: KlaviyoWaitlistJoinPayload = {
      variantId,
      email,
      name,
    }
    await callApi<WaitlistJoinResponse>(
      url,
      klaviyoWaitlistJoinPayload,
      headers
    )
  }
}

/**
 * Calls demo booking API page.
 */
export const bookADemo = async (
  locale: Locale,
  service: Service,
  bookingTargetId: string,
  hasAcceptedTerms: boolean,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber?: string,
  company?: string
) => {
  const url = getApiUrl(service, 'book-a-demo')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoBookADemoPayload: KlaviyoBookADemoPayload = {
      listId: bookingTargetId,
      email,
      firstName,
      lastName,
      phoneNumber,
      company,
    }
    await callApi<BookADemoResponse>(url, klaviyoBookADemoPayload, headers)
  }

  if (service === 'hubSpot') {
    const hubSpotBookADemoPayload: HubSpotBookADemoPayload = {
      formId: bookingTargetId,
      consent: !!hasAcceptedTerms,
      email,
      firstName,
      lastName,
      phoneNumber,
      company,
    }
    await callApi<BookADemoResponse>(url, hubSpotBookADemoPayload, headers)
  }
}

/**
 * Calls contact API page.
 */
export const contact = async (
  locale: Locale,
  service: Service,
  contactTargetId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  company: string,
  message?: string,
  emailCustomFieldName?: string,
  phoneCustomFieldName?: string,
  companyCustomFieldName?: string
) => {
  const url = getApiUrl(service, 'contact')
  const headers = getApiHeaders(locale)

  if (service === 'clickup') {
    const clickUpContactPayload: ClickUpContactPayload = {
      listId: contactTargetId,
      firstName,
      lastName,
      email,
      phone,
      company,
      message,
      emailCustomFieldName,
      phoneCustomFieldName,
      companyCustomFieldName,
    }
    await callApi<ContactResponse>(url, clickUpContactPayload, headers)
  }
}
